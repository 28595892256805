import { Link } from 'react-router-dom'
import * as React from 'react'
import Modal from '../../components/contactUs/rightSide/Modal'
//import menuStyles from './Menu.module.css'
import './Override.css'
import NewCarLinks from './components/newCarsLink/NewCarLinks'
import Accordion from '../../components/contactUs/Accordion'
import MenuLink from './components/menuLink/MenuLink'
//import headBanner from '../../assets/images/head-new-banner.png'
import { EnvironmentContext } from '../../components/providers/EnvironmentProvider'
import { CommonEvent } from '../../models/event/CommonEvent'
import { useEffect } from 'react'
import { useAppDispatch } from '../../components/common/hooks/reduxHooks'
import { contactUsActions } from '../../utils/slices/contactUsChatSlice'
import NewArrivals from './components/newArrivals/NewArrivals'
import Scrollbars from 'react-custom-scrollbars-2'
import ComingSoon from './components/comingSoon/ComingSoon'
import EventCaption from '../../components/layout/eventCaption/Index'
import styles from './styles.module.scss'
// import { useAppDispatch, useAppSelector } from '../../components/common/hooks/reduxHooks'
// import ModalWithChildren from '../../components/common/modals/ModalWithChildren'
// import EasterHours from '../../components/layout/easterHours/EasterHours'
// import ModalWithChildren from '../../components/common/modals/ModalWithChildren'
// import ChristmasOpening from '../../components/layout/ChristmasOpening'

const Menu = ({ onContactUsClick }: any) => {
  const [branche, setBranche] = React.useState()
  const [event, setEvent] = React.useState<CommonEvent>()
  const [showModal, setShowModal] = React.useState(false)
  //const [showChristmasModal, setShowChristmasModal] = React.useState(false)
  //const [showEasterModal, setShowEasterModal] = React.useState(false)
  const ref = React.useRef<any>()

  // const isActionFormExpanded = useAppSelector((state) => state.contactUs.isActionFormExpanded)
  const dispatch = useAppDispatch()

  const { env } = React.useContext<any>(EnvironmentContext)

  useEffect(() => {
    if (env.events) {
      let smallBannerEvent = env.events.filter(
        (e: CommonEvent) => e.isSmallHeaderBannerActive === true && e.isEventActive === true,
      )[0]
      setEvent(smallBannerEvent)
    }
  }, [env])

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleShowModal = (e: any) => {
    setBranche(e)
    setShowModal(true)
  }

  const handleChatClick = () => {
    ref.current.click()
    dispatch(contactUsActions.setChatExpanded(true))
  }

  const handleOnMenuItemClick = () => {
    ref?.current?.click()
    document.getElementById('mainMenuWrapper')?.classList.remove('main-menu__wrapper--active')
    document.getElementById('headerMobile')?.classList.remove('header-mobile--open')
  }

  return (
    <>
      {branche && <Modal branch={branche} showModal={showModal} onClose={handleCloseModal} />}

      {/* <ModalWithChildren
        showModal={showChristmasModal}
        setShowModal={setShowChristmasModal}
        className='pure-modal__withChildren-christmas'
      >
        <ChristmasOpening />
      </ModalWithChildren> */}

      {/* <ModalWithChildren
        showModal={showEasterModal}
        setShowModal={setShowEasterModal}
        className='pure-modal__withChildren-easter'
      >
        <EasterHours />
      </ModalWithChildren> */}

      <div id='mainMenuWrapper' className='main-menu__wrapper'></div>
      <header className='header'>
        <div className='header__wrapper'>
          <div className='header__group'>
            <div ref={ref} className='header__burger'>
              <div className='header__burger-wrapper'>
                <div className='icon-left'></div>
                <div className='icon-right'></div>
              </div>
            </div>

            <Link
              className='header__logo'
              to={{
                pathname: `/`,
              }}
            >
              <img src='/img/logo.svg' alt='Advance Motors' aria-label='Advance Motors' />
            </Link>

            <ul className='header__nav' style={{ width: 'max-content' }}>
              <li>
                <MenuLink title='New Cars'>
                  <>
                    <div className='header__submenu-group'>
                      <a href='/new-cars-offers'>New Cars offers</a>
                      <ul className={`header__submenu-list header__submenu-list--wide ${styles.headerSubmenu}`}>
                        <NewCarLinks varClassId='1' />
                      </ul>
                    </div>
                    <div className='header__submenu-group'>
                      <a href='/new-cars-offers'>New Arrivals</a>
                      <ul className={`header__submenu-list header__submenu-list--wide ${styles.headerSubmenu}`}>
                        <NewArrivals />
                      </ul>
                    </div>
                    <div className='header__submenu-group'>
                      <a href='/new-cars-offers'>Coming soon</a>
                      <ul className='header__submenu-list'>
                        <ComingSoon />
                      </ul>
                    </div>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/new-vehicles-stock`,
                        }}
                      >
                        New Cars Stock locator
                      </Link>
                    </div>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/new-car-motability`,
                        }}
                      >
                        Motability Cars
                      </Link>
                    </div>
                    <div className='header__submenu-group'>
                      <a href='/new-car-order'>Order Your New Vauxhall</a>
                    </div>
                  </>
                </MenuLink>
              </li>
              <li>
                <MenuLink title='New Vans'>
                  <>
                    <div className='header__submenu-group'>
                      <a href='/new-vans-offers'>New Vans offers</a>
                      <ul className='header__submenu-list header__submenu-list--wide'>
                        <NewCarLinks varClassId='2' />
                      </ul>
                    </div>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/new-vehicles-stock`,
                        }}
                      >
                        New Vans Stock locator
                      </Link>
                    </div>
                  </>
                </MenuLink>
              </li>
              <li>
                <Link
                  to={{
                    pathname: `/stock-car`,
                  }}
                >
                  Used Vehicles
                </Link>
              </li>
              <li>
                <MenuLink title='Electric'>
                  <>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/electric-cars`,
                        }}
                      >
                        New Electric Cars
                      </Link>

                      <ul className='header__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-car-offer`,
                            }}
                          >
                            Electric Cars Specials
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/electric-cars`,
                        }}
                      >
                        More about electric vehicles
                      </Link>

                      <ul className='header__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-charging`,
                            }}
                          >
                            Electric Vehicle Charging
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/plug-and-go`,
                            }}
                          >
                            Plug & Go
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-servicing`,
                            }}
                          >
                            Electric Vehicle Servicing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-vehicle-range`,
                            }}
                          >
                            The Top 5 Factors that Affect Mileage
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </>
                </MenuLink>
              </li>
              <li>
                <a href='/value-car'>Value Your Car</a>
              </li>

              <li>
                <MenuLink title='Servicing & Parts'>
                  <>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/service-booking`,
                        }}
                      >
                        Online Service & MOT
                      </Link>

                      <ul className='header__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/service-booking`,
                            }}
                          >
                            Online Service & MOT booking
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/service-plan`,
                            }}
                          >
                            Service Plans
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/part-request`,
                        }}
                      >
                        Part request
                      </Link>
                      <ul className='header__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/part-request`,
                            }}
                          >
                            Part request
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/service-booking`,
                        }}
                      >
                        Service Offer
                      </Link>
                      <ul className='header__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/service-special-offer`,
                            }}
                          >
                            Service Special Offer
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/vehicle-health-check`,
                            }}
                          >
                            Vehicle Health Check
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/rac-breakdown-cover`,
                            }}
                          >
                            Free RAC
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/drive-now-pay-later`,
                            }}
                          >
                            Drive now, Pay later
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </>
                </MenuLink>
              </li>
              <li>
                <MenuLink title='Motability'>
                  <>
                    <div className='header__submenu-group'>
                      <Link
                        to={{
                          pathname: `/motability-car-scheme`,
                        }}
                      >
                        Motability
                      </Link>

                      <ul className='header__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/motability-car-scheme`,
                            }}
                          >
                            Motability car scheme
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/new-car-motability`,
                            }}
                          >
                            Motability car search
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-car-motability`,
                            }}
                          >
                            Explore electric cars options
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/motability-adaptations`,
                            }}
                          >
                            Motability Adaptations
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </>
                </MenuLink>
              </li>
            </ul>
          </div>
          <div className='header__group'>
            {event && (
              <div className='header__promo'>
                <Link
                  to={{
                    pathname: `/sales-offer/${event.symbolCode}`,
                  }}
                >
                  <img
                    src={event.smallHeaderBanner}
                    style={{ maxHeight: '52px' }}
                    alt='Buy any new Vauxhall from an Advance Vauxhall retailer'
                  />
                </Link>
              </div>
            )}
            {/* <a
              href='/'
              onClick={(e) => {
                e.preventDefault()
                setShowChristmasModal(true)
              }}
              className='header__info header__info--christmas'
              style={{ backgroundImage: 'url(/img/snowflake.svg)', textDecoration: 'none' }}
            >
              <p>Christmas Opening Hours</p>
            </a> */}
            {/* <a
              href='/'
              onClick={(e) => {
                e.preventDefault()
                setShowEasterModal(true)
              }}
              className='header__info header__info--christmas'
              style={{ textDecoration: 'none', padding: '10px 20px 10px 20px' }}
            >
              <p>Easter Opening Hours</p>
            </a> */}
            <div className='header__links' style={{ marginLeft: '14px' }}>
              <div className='header__link' style={{ marginLeft: '17px' }}>
                <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
                  <path d='M0 0h24v24H0z' fill='none'></path>
                  <path d='M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z'></path>
                </svg>
                <a
                  href='/contact-us'
                  onClick={(e) => {
                    e.preventDefault()
                    onContactUsClick()
                  }}
                  className='link toggle-contact-menu header__link'
                  style={{ width: 'max-content' }}
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div className='header__logo-last' style={{ marginLeft: '9px' }}>
              <img src='/img/logo-2.svg' alt='Advance Vauxhall' />
            </div>
          </div>
        </div>
        <div id='headerMobile' className='header-mobile'>
          <div className='header-mobile__layout'>
            {event && (
              <div className='header-mobile__promo'>
                <Link
                  to={{
                    pathname: `/sales-offer/${event.symbolCode}`,
                  }}
                >
                  <img
                    src={event.smallHeaderBanner}
                    style={{ width: '100%' }}
                    alt='Buy any new Vauxhall from an Advance Vauxhall retailer'
                  />
                </Link>
              </div>
            )}

            {/* <a
              href='/'
              onClick={(e) => {
                e.preventDefault()
                setShowChristmasModal(true)
              }}
              className='header__info header__info--mobile header__info--christmas'
              style={{ backgroundImage: 'url(/img/snowflake.svg)', textDecoration: 'none' }}
            >
              <p>Christmas Opening Hours</p>
            </a> */}

            {/* <a
              href='/'
              onClick={(e) => {
                e.preventDefault()
                setShowEasterModal(true)
              }}
              className='header__info header__info--mobile header__info--christmas'
              style={{ textDecoration: 'none', padding: '10px 20px 10px 20px' }}
            >
              <p>Easter Opening Hours</p>
            </a> */}

            <div className='header__link--mobile header-mobile__menu-link'>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                <div className='header-mobile__contact'>
                  <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
                    <path d='M0 0h24v24H0z' fill='none'></path>
                    <path d='M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z'></path>
                  </svg>
                  <span>Contact Us</span>
                </div>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                  <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                  <path fill='none' d='M0 0h24v24H0V0z'></path>
                </svg>
              </a>
              <div className='header-mobile__submenu' style={{ display: 'block' }}>
                <div className='header-mobile__back'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    enableBackground='new 0 0 24 24'
                    height='24'
                    viewBox='0 0 24 24'
                    width='24'
                  >
                    <rect fill='none' height='24' width='24'></rect>
                    <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                  </svg>
                  <span>Contact Us</span>
                </div>

                <Accordion handleShowModal={handleShowModal} scrollable={true} onChatClick={handleChatClick} />
              </div>
            </div>
            <ul className='header-mobile__menu'>
              <li>
                <div className='header-mobile__menu-link'>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <span>New Cars</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                      <path fill='none' d='M0 0h24v24H0V0z'></path>
                    </svg>
                  </a>
                  <div className='header-mobile__submenu'>
                    <div className='header-mobile__back'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        enableBackground='new 0 0 24 24'
                        height='24'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <rect fill='none' height='24' width='24'></rect>
                        <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                      </svg>
                      <span>New Cars</span>
                    </div>
                    <Scrollbars
                      autoHeight
                      autoHeightMin='100vh'
                      autoHide
                      renderTrackHorizontal={(props) => (
                        <div {...props} className='track-horizontal' style={{ display: 'none' }} />
                      )}
                      renderView={({ style, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...style,
                            paddingRight: '15px',
                            paddingBottom: '15px',
                            overflowX: 'hidden',
                          }}
                        />
                      )}
                      renderThumbVertical={({ style, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...style,
                            width: '3px',
                            borderRadius: '4px',
                            backgroundColor: '#d4141c',
                          }}
                        />
                      )}
                    >
                      <div className='header-mobile__submenu-list-wrapper'>
                        <a href='/new-cars-offers'>New Cars offers</a>
                        <ul className='header-mobile__submenu-list'>
                          <NewCarLinks varClassId='1' onClick={handleOnMenuItemClick} />
                        </ul>

                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                        >
                          New Arrivals
                        </a>
                        <ul className='header-mobile__submenu-list'>
                          <NewArrivals onClick={handleOnMenuItemClick} />
                        </ul>
                        <Link
                          to={{
                            pathname: `/new-vehicles-stock`,
                          }}
                        >
                          New Cars Stock locator
                        </Link>
                        <a
                          href='/'
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                        >
                          Coming Soon
                        </a>
                        <ul className='header-mobile__submenu-list'>
                          <ComingSoon />
                        </ul>
                        <Link
                          to={{
                            pathname: `/new-car-motability`,
                          }}
                        >
                          Motability Cars
                        </Link>

                        <a href='/new-car-order'>Order Your New Vauxhall</a>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </li>
              <li>
                <div className='header-mobile__menu-link'>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <span>New Vans</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                      <path fill='none' d='M0 0h24v24H0V0z'></path>
                    </svg>
                  </a>
                  <div className='header-mobile__submenu'>
                    <div className='header-mobile__back'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        enableBackground='new 0 0 24 24'
                        height='24'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <rect fill='none' height='24' width='24'></rect>
                        <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                      </svg>
                      <span>New Vans</span>
                    </div>
                    <div className='header-mobile__submenu-list-wrapper'>
                      <a href='/new-vans-offers'>New Vans offers</a>
                      <ul className='header-mobile__submenu-list'>
                        <NewCarLinks varClassId='2' onClick={handleOnMenuItemClick} />
                      </ul>

                      <Link
                        to={{
                          pathname: `/new-vehicles-stock`,
                        }}
                      >
                        New Vans Stock locator
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='header-mobile__menu-link'>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <span>Used Vehicles</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                      <path fill='none' d='M0 0h24v24H0V0z'></path>
                    </svg>
                  </a>
                  <div className='header-mobile__submenu'>
                    <div className='header-mobile__back'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        enableBackground='new 0 0 24 24'
                        height='24'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <rect fill='none' height='24' width='24'></rect>
                        <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                      </svg>
                      <span>Used Vehicles</span>
                    </div>
                    <div className='header-mobile__submenu-list-wrapper'>
                      <Link
                        to={{
                          pathname: `/stock-car`,
                        }}
                      >
                        Used Vehicles
                      </Link>

                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/stock-car`,
                            }}
                          >
                            Used Vehicles
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='header-mobile__menu-link'>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <span>Electric</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                      <path fill='none' d='M0 0h24v24H0V0z'></path>
                    </svg>
                  </a>
                  <div className='header-mobile__submenu'>
                    <div className='header-mobile__back'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        enableBackground='new 0 0 24 24'
                        height='24'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <rect fill='none' height='24' width='24'></rect>
                        <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                      </svg>
                      <span>New Electric Cars</span>
                    </div>
                    <div className='header-mobile__submenu-list-wrapper'>
                      <Link
                        to={{
                          pathname: `/electric-cars`,
                        }}
                      >
                        New Electric Cars
                      </Link>

                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-car-offer`,
                            }}
                          >
                            Electric Car Specials
                          </Link>
                        </li>
                      </ul>

                      <Link
                        to={{
                          pathname: `/electric-cars`,
                        }}
                      >
                        More about electric vehicles
                      </Link>
                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-charging`,
                            }}
                          >
                            Electric Vehicle Charging
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/plug-and-go`,
                            }}
                          >
                            Plug & Go
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-servicing`,
                            }}
                          >
                            Electric Vehicle Servicing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-vehicle-range`,
                            }}
                          >
                            The Top 5 Factors that Affect Mileage
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='header-mobile__menu-link'>
                  <a
                    href='/value-car'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <span>Value Your Car</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                      <path fill='none' d='M0 0h24v24H0V0z'></path>
                    </svg>
                  </a>
                  <div className='header-mobile__submenu'>
                    <div className='header-mobile__back'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        enableBackground='new 0 0 24 24'
                        height='24'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <rect fill='none' height='24' width='24'></rect>
                        <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                      </svg>
                      <span>Value Your Car</span>
                    </div>
                    <div className='header-mobile__submenu-list-wrapper'>
                      <Link
                        to={{
                          pathname: `/value-car`,
                        }}
                      >
                        Value Your Car
                      </Link>

                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/value-car`,
                            }}
                          >
                            Value Your Car
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='header-mobile__menu-link'>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <span>Servicing &amp; Parts</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                      <path fill='none' d='M0 0h24v24H0V0z'></path>
                    </svg>
                  </a>
                  <div className='header-mobile__submenu'>
                    <div className='header-mobile__back'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        enableBackground='new 0 0 24 24'
                        height='24'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <rect fill='none' height='24' width='24'></rect>
                        <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                      </svg>
                      <span>Servicing &amp; Parts</span>
                    </div>
                    <div className='header-mobile__submenu-list-wrapper'>
                      <Link
                        to={{
                          pathname: `/service-booking`,
                        }}
                      >
                        Online Service &amp; MOT booking
                      </Link>
                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/service-booking`,
                            }}
                          >
                            Online Service &amp; MOT booking
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/service-plan`,
                            }}
                          >
                            Service Plan
                          </Link>
                        </li>
                      </ul>

                      <Link
                        to={{
                          pathname: `/part-request`,
                        }}
                      >
                        Part request
                      </Link>
                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/part-request`,
                            }}
                          >
                            Part request
                          </Link>
                        </li>
                      </ul>

                      <Link
                        to={{
                          pathname: `/service-special-offer`,
                        }}
                      >
                        Service Offer
                      </Link>
                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/service-special-offer`,
                            }}
                          >
                            Service Special Offer
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/vehicle-health-check`,
                            }}
                          >
                            Vehicle Health Check
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/rac-breakdown-cover`,
                            }}
                          >
                            Free RAC
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/drive-now-pay-later`,
                            }}
                          >
                            Drive now, pay later
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className='header-mobile__menu-link'>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <span>Motability</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                      <path d='M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z'></path>
                      <path fill='none' d='M0 0h24v24H0V0z'></path>
                    </svg>
                  </a>
                  <div className='header-mobile__submenu'>
                    <div className='header-mobile__back'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        enableBackground='new 0 0 24 24'
                        height='24'
                        viewBox='0 0 24 24'
                        width='24'
                      >
                        <rect fill='none' height='24' width='24'></rect>
                        <path d='M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z'></path>
                      </svg>
                      <span>Motability</span>
                    </div>
                    <div className='header-mobile__submenu-list-wrapper'>
                      <Link
                        to={{
                          pathname: `/motability-car-scheme`,
                        }}
                      >
                        Motability
                      </Link>
                      <ul className='header-mobile__submenu-list'>
                        <li>
                          <Link
                            to={{
                              pathname: `/motability-car-scheme`,
                            }}
                          >
                            Motability car scheme
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/new-car-motability`,
                            }}
                          >
                            Motability car search
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/electric-car-motability`,
                            }}
                          >
                            Explore electric cars options
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathname: `/motability-adaptations`,
                            }}
                          >
                            Motability Adaptations
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <EventCaption /> */}
      </header>
    </>
  )
}

export default Menu
